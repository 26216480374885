@font-face {
	font-family: "Syne Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Syne Regular"), url("/fonts/Syne-Regular.woff") format("woff");
}

@font-face {
	font-family: "Syne Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Syne Bold"), url("/fonts/Syne-Bold.woff") format("woff");
}

body {
	margin: 0;
	padding: 0;

	font-family: "Arimo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	color: white;
	background-color: #353535;
	position: relative;
	height: 100%;
}

* {
	box-sizing: border-box;
}

h1 {
	font-family: "Syne Bold";
	font-size: 62px;
	margin: 0;
}

@media (max-height: 900px) {
	h1 {
		margin-bottom: 18px !important;
	}
}

html,
body {
	padding: 0;
	margin: 0;
}
